import React, { useEffect } from "react";
import { Switch, Route, BrowserRouter } from "react-router-dom";
import Auth from "./views/auth";
import PatientHome from "./views/patient-home";
import {
  AUTH_ROUTE,
  PATIENT_HOME_ROUTE,
  PATIENT_PROFILE_ROUTE,
  PATIENT_JOBS_ROUTE,
  PATIENT_JOB_ROUTE,
  PATIENT_NEW_JOB_ROUTE,
  PATIENT_NEW_JOB_SUCCESS_ROUTE,
  PATIENT_ACCOUNT_HISTORY_ROUTE,
  APPLICANT_ROUTE,
  FORGOT_PASSWORD_ROUTE,
  VERIFY_EMAIL_RESET_PASSWORD_ROUTE,
  SIGN_UP_ROUTE,
  PATIENT_ACCOUNT_INFO_ROUTE,
  PATIENT_HOME_NO_PROFILE_ROUTE,
  ADMIN_ROUTE,
  TERMS_OF_SERVICE_ROUTE,
  DISCLOSURE_ROUTE,
  PRIVACY_ROUTE,
  TERMS_OF_SERVICE_APP_ROUTE,
  DISCLOSURE_APP_ROUTE,
  PRIVACY_APP_ROUTE,
  RESET_PASSWORD_COMPLETE_ROUTE,
  PATIENT_EDIT_JOB_ROUTE,
} from "./constants/routes";
import PatientProfile from "./views/patient-profile";
import PatientJob from "./views/patient-job";
import PatientNewJob from "./views/patient-add-edit-job";
import { loadStripe } from "@stripe/stripe-js";
import PatientAccountHistory from "./views/patient-account-history";
import Layout from "./components/layout";
import Providers from "./components/providers";
import PatientJobs from "./views/patient-jobs";
import { Applicant } from "./views/applicant";
import ForgotPassword from "./views/forgot-password";
import SignUp from "./views/sign-up";
import PatientAccountInfo from "./views/patient-account-info";
import PatientAddEditJobStepSuccess from "./views/patient-add-edit-job-success";
import PatientHomeNoProfile from "./views/patient-home-no-profile";
import { Admin } from "./views/admin";
import TermsOfService from "./views/terms";
import Disclosure from "./views/disclosure";
import Privacy from "./views/privacy";
import VerifyEmailResetPassword from "./views/verify-email-reset-password";
import ResetPasswordComplete from "./views/reset-password-complete";
import * as FullStory from "@fullstory/browser";

const StripePublicKey = process.env.REACT_APP_STRIPE_PUBLIC_KEY;
const FullStoryKey = process.env.REACT_APP_FULL_STORY_KEY;
export const stripePromise = loadStripe(StripePublicKey || "");
export const fullStoryKey = FullStoryKey || "";

const App: React.FC = () => {
  useEffect(() => {
    if (fullStoryKey) {
      FullStory.init({ orgId: fullStoryKey });
    }
  }, [fullStoryKey]);

  return (
    <Providers>
      <BrowserRouter>
        <Switch>
          <Route exact path={TERMS_OF_SERVICE_APP_ROUTE} component={TermsOfService} />
          <Route exact path={DISCLOSURE_APP_ROUTE} component={Disclosure} />
          <Route exact path={PRIVACY_APP_ROUTE} component={Privacy} />
          <Layout>
            <Route exact path={AUTH_ROUTE} component={Auth} />
            <Route exact path={SIGN_UP_ROUTE} component={SignUp} />
            <Route exact path={TERMS_OF_SERVICE_ROUTE} component={TermsOfService} />
            <Route exact path={DISCLOSURE_ROUTE} component={Disclosure} />
            <Route exact path={PRIVACY_ROUTE} component={Privacy} />
            <Route exact path={FORGOT_PASSWORD_ROUTE} component={ForgotPassword} />
            <Route exact path={VERIFY_EMAIL_RESET_PASSWORD_ROUTE} component={VerifyEmailResetPassword} />
            <Route exact path={RESET_PASSWORD_COMPLETE_ROUTE} component={ResetPasswordComplete} />
            <Route exact path={PATIENT_HOME_ROUTE} component={PatientHome} />
            <Route exact path={PATIENT_HOME_NO_PROFILE_ROUTE} component={PatientHomeNoProfile} />
            <Route exact path={PATIENT_PROFILE_ROUTE} component={PatientProfile} />
            <Route exact path={PATIENT_JOBS_ROUTE} component={PatientJobs} />
            <Route exact path={PATIENT_JOB_ROUTE} component={PatientJob} />
            <Route exact path={PATIENT_NEW_JOB_ROUTE} component={PatientNewJob} />
            <Route exact path={PATIENT_EDIT_JOB_ROUTE} component={PatientNewJob} />
            <Route exact path={PATIENT_NEW_JOB_SUCCESS_ROUTE} component={PatientAddEditJobStepSuccess} />
            <Route exact path={PATIENT_ACCOUNT_HISTORY_ROUTE} component={PatientAccountHistory} />
            <Route exact path={PATIENT_ACCOUNT_INFO_ROUTE} component={PatientAccountInfo} />
            <Route exact path={APPLICANT_ROUTE} component={Applicant} />
            <Route exact path={ADMIN_ROUTE} component={Admin} />
          </Layout>
        </Switch>
      </BrowserRouter>
    </Providers>
  );
};

export default App;
