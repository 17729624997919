import React, { useState, useEffect } from "react";
import useRequireAuth from "../hooks/use-require-auth";
import { firebaseFunctions, firestore } from "../firebase";
import { PRACTITIONERS_PROFILES_COLLECTION } from "../constants/collections";
import { MainContentWrapper } from "../components/layout";
import { PageLoading } from "../components/loading";
// eslint-disable-next-line no-unused-vars
import { RouteComponentProps, Link, useHistory } from "react-router-dom";
import styled from "styled-components";
import { HEADER_HEIGHT } from "../components/header";
import Container from "react-bootstrap/Container";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { BigBoldLabel, BigLabel, H1, Notes, RegularText } from "../components/text";
import { PATIENT_JOB_ROUTE_PATH, PATIENT_JOBS_ROUTE } from "../constants/routes";
import { Spacer1, Spacer2, Spacer3, Spacer4, Spacer5 } from "../components/spacers";
import { Avatar } from "../components/avatar";
import { theme } from "../components/theme";
import ReactStars from "react-rating-stars-component";
import { HR } from "../components/hr";
// eslint-disable-next-line no-unused-vars
import { Job, JobApplicant, JobApplicantFields } from "../types/jobs";
import { updateApplication } from "../hooks/use-jobs";
import { Button } from "../components/buttons";
// eslint-disable-next-line no-unused-vars
import { Applicant as ApplicantType, fromFirebase } from "../types/applicant";
import GenericError from "../components/generic-error";
import ModalPopup from "../components/modal-popup";
import moment from "moment";
import { DAY, TIME } from "../constants/dates";
import { NOTIFY_PROVIDER } from "../constants/callable-functions";
import { CANCELLED_JOB_NOTIFICATION_TYPE, CONFIRMED_APPLICANT_NOTIFICATION_TYPE } from "../constants/notifications";
import { MIN_SHIFTS_TO_SHOW_REVIEWS } from "../types/practitioner";
import ReviewsModal from "../components/reviews-modal";

interface MatchParams {
  jobId: string;
  applicantId: string;
}

interface ApplicantProps extends RouteComponentProps<MatchParams> {}

const Applicant: React.FC<ApplicantProps> = (props) => {
  const job = props.location.state as Job;
  const requireAuth = useRequireAuth();
  const history = useHistory();

  const [loading, setLoading] = useState(true);
  const [applicant, setApplicant] = useState<ApplicantType>();
  const [error, setError] = useState<boolean>();
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [isReviewsModalVisible, setIsReviewsModalVisible] = useState<boolean>(false);

  useEffect(() => {
    if (!props.match.params.applicantId) return;

    firestore
      .collection(PRACTITIONERS_PROFILES_COLLECTION)
      .doc(props.match.params.applicantId)
      .get()
      .then((doc) => {
        setApplicant(fromFirebase(doc));
        setLoading(false);
      })
      .catch((e) => {
        setError(true);
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!requireAuth.user || loading) {
    return (
      <MainContentWrapper color="white">
        <PageLoading />
      </MainContentWrapper>
    );
  }

  const confirmedApplicant = job.applicants?.find((applicant: JobApplicant) => applicant.confirmed);

  if (!applicant) return <></>;

  return (
    <>
      {error ? (
        <MainContentWrapper color="white">
          <GenericError />
        </MainContentWrapper>
      ) : (
        <>
          {confirmedApplicant ? (
            <>
              <ModalPopup
                isVisible={isModalVisible}
                setIsVisible={setIsModalVisible}
                title="Cancel applicant"
                mainCopy={
                  <span>
                    Are you sure you want to cancel <b>{applicant.displayName}</b>?
                  </span>
                }
                hint={
                  <span>
                    This will remove {applicant.displayName} <br /> from the applicant list.
                  </span>
                }
                onConfirm={() => {
                  updateApplication(job, applicant, JobApplicantFields.confirmed, false).then(() => history.goBack());

                  const notifyProvider = firebaseFunctions.httpsCallable(NOTIFY_PROVIDER);
                  notifyProvider({
                    applicantId: confirmedApplicant?.uid,
                    type: CANCELLED_JOB_NOTIFICATION_TYPE,
                    jobId: job.id,
                  }).catch((error) => console.error(error));
                }}
                confirmCopy="Yes"
                cancelCopy="Cancel"
              />
            </>
          ) : (
            <ModalPopup
              isVisible={isModalVisible}
              setIsVisible={setIsModalVisible}
              title="Confirm"
              mainCopy={
                <span>
                  Confirm <b>{applicant.displayName}</b> for <b>{moment(job.date).format(DAY)}</b> for{" "}
                  <b>{`${job.shiftLength} Hour${job.shiftLength > 1 ? "s" : ""}`}</b> starting at{" "}
                  <b>{moment(job.date).format(TIME)}</b>?
                </span>
              }
              onConfirm={() => {
                updateApplication(job, applicant, JobApplicantFields.confirmed, true, requireAuth.profile).then(() =>
                  history.push(PATIENT_JOBS_ROUTE)
                );

                const notifyProvider = firebaseFunctions.httpsCallable(NOTIFY_PROVIDER);
                notifyProvider({
                  applicantId: applicant.id,
                  type: CONFIRMED_APPLICANT_NOTIFICATION_TYPE,
                  jobId: job.id,
                }).catch((error) => console.error(error));
              }}
              confirmCopy="Book"
              cancelCopy="Cancel"
            />
          )}
          <ApplicantHeader>
            <Container className="d-flex justify-content-between">
              <Link to={`${PATIENT_JOB_ROUTE_PATH}/${props.match.params.jobId}`}>
                <FontAwesomeIcon icon={faArrowLeft} size={"3x"} color="white" />
              </Link>
              <H1>{applicant.displayName}</H1>
              <span />
            </Container>
          </ApplicantHeader>
          <Spacer3 />
          <MainContentWrapper color="white" marginTop="0">
            <div className="d-flex">
              <Left>
                <Avatar applicant={applicant} width={200} avatarIconSize="8x" single noFilters />
                <Spacer3 />
                {applicant.shiftsWorked >= MIN_SHIFTS_TO_SHOW_REVIEWS && (
                  <>
                    <ReactStars
                      count={5}
                      value={applicant.rating}
                      edit={false}
                      onChange={() => {}}
                      size={24}
                      isHalf
                      activeColor={theme.colors.yellowOrange}
                      color={theme.colors.alto}
                    />
                    <Spacer1 />
                    <Clickable>
                      <p onClick={() => setIsReviewsModalVisible(true)}>Read Reviews</p>
                    </Clickable>
                    <Spacer1 />
                    <HR />
                    <Spacer3 />
                    <BigLabel>SHIFTS WORKED:</BigLabel>
                    <H1>{applicant.shiftsWorked}</H1>
                  </>
                )}
              </Left>
              <Spacer5 />
              <Spacer4 />
              <div>
                <Spacer3 />
                <BigBoldLabel>TIME CAREGIVING:</BigBoldLabel>
                <RegularText>{applicant.timeCaregiving}</RegularText>
                <Spacer2 />
                <BigBoldLabel>FAVORITE THING ABOUT CAREGIVING:</BigBoldLabel>
                <RegularText>{applicant.favouriteThingCaregiving}</RegularText>
                <Spacer2 />
                <BigBoldLabel>GREATEST STRENGTH IN CAREGIVING:</BigBoldLabel>
                <RegularText>{applicant.greatestStrengthCaregiving}</RegularText>
                <Spacer2 />
                <BigLabel>BIO</BigLabel>
                <Notes>{applicant.bio}</Notes>
                <Spacer3 />
                {confirmedApplicant ? (
                  <>
                    <Button onClick={async () => setIsModalVisible(true)}>Cancel</Button>
                  </>
                ) : (
                  <Button onClick={async () => setIsModalVisible(true)}>Book</Button>
                )}
                <Spacer4 />
              </div>
            </div>
            <ReviewsModal
              isVisible={isReviewsModalVisible}
              setIsVisible={() => setIsReviewsModalVisible(false)}
              applicant={applicant}
            />
          </MainContentWrapper>
        </>
      )}
    </>
  );
};

const Clickable = styled.div`
  cursor: pointer;
`;

const Left = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ApplicantHeader = styled.div`
  margin-top: ${HEADER_HEIGHT}px;
  background-color: ${(props) => props.theme.colors.jellyBean};
  color: white;
  padding: 24px 0;

  h1 {
    margin: 0;
  }
`;

export { Applicant };
