import React, { useEffect, useState } from "react";
import { Alert, Col, Form, FormControl, FormLabel, Row } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { CheckboxFormGroup } from "../components/checkbox-form-group";
// eslint-disable-next-line no-unused-vars
import { FormStepProps } from "./patient-add-edit-job";
import { faClock } from "@fortawesome/free-solid-svg-icons";
import { theme } from "../components/theme";
import { shiftLengths } from "../types/jobs";
import { Spacer1, Spacer2, Spacer3 } from "../components/spacers";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { H3, H4, RegularText, requiredStar } from "../components/text";
import moment from "moment";
import { DateWrapper, DatePickerDateCustom } from "../components/dates";
import useScrollTop from "../hooks/use-scroll-to-top";
import enUS from "date-fns/locale/en-US";
import { shiftToPrices } from "../constants/prices";
// eslint-disable-next-line no-unused-vars
import { FormControlElement } from "../types/form";
import TextFormGroup from "../components/text-form-group";
import { DropdownFormControl } from "../components/forms";
import { Genders } from "../types/user";
import { calculateRecurringDates } from "../hooks/use-jobs";
import { getTimeToEdit } from "../constants/timezones";
import { usePatientAuth } from "../hooks/use-patient-auth";

const PatientAddEditJobStep1: React.FC<FormStepProps> = (props) => {
  const { currentJob, handleDateChange, handleCheckboxChange, setCurrentJob, handleFieldChange, profileName, editMode } = props;
  useScrollTop();
  const showOnBehalfSection = false;
  const [selectedTime, setSelectedTime] = useState<Date>();
  const { profile } = usePatientAuth();


  const selectIsOwner = (a: any, event: React.ChangeEvent<FormControlElement>) => {
    if (setCurrentJob) {
      setCurrentJob({ ...currentJob, isOwner: event.currentTarget.value === "true", gender: Genders.MALE });
    }
  };

  const [recurringEndDate, setRecurringEndDate] = useState<any>();

  const selectIsRecurring = (a: any, { currentTarget }: React.ChangeEvent<FormControlElement>) => {
    setCurrentJob && setCurrentJob({ ...currentJob, isRecurring: currentTarget.value === "true" });
  };

  const selectRecurringDays = (a: any, { currentTarget }: React.ChangeEvent<FormControlElement>) => {
    let selectedDays = currentJob?.recurringDays || [];
    if (selectedDays?.some(day => day === parseInt(currentTarget.value))) {
      selectedDays = selectedDays.filter(day => day !== parseInt(currentTarget.value));
    } else {
      selectedDays?.push(parseInt(currentTarget.value));
    }

    let recurringDates = [];
    if (currentJob?.recurringEndDate) {
      recurringDates = getRecurringDates(moment(currentJob?.date).clone(), moment(currentJob?.recurringEndDate).clone(), selectedDays || []);
    }

    setCurrentJob && setCurrentJob({ ...currentJob, recurringDays: selectedDays, recurringDates });
  };

  const selectRecurringEndDate = (date: any) => {
    setRecurringEndDate(date);
    const recurringDates = getRecurringDates(moment(currentJob?.date).clone(), moment(date).clone(), currentJob?.recurringDays || []);
    setCurrentJob && setCurrentJob({ ...currentJob, recurringEndDate: date, recurringDates });
  };

  const getRecurringDates = (initDate: any, endDate: any, selectedDays: number[]) => {
    return calculateRecurringDates(initDate, endDate, selectedDays);
  };

  const onTimeChange = (date: Date) => {
    setSelectedTime(date);
    handleDateChange(date);
  };

  useEffect(() => {
    if (editMode && (selectedTime === undefined) && currentJob?.date !== undefined) {
      const time = getTimeToEdit(currentJob?.date, profile?.city);
      setSelectedTime(time);
    } else {
      setSelectedTime(currentJob?.date);
    }
  }, [editMode, currentJob?.date]);

  return (
    <>
      <Spacer1 />
      <Row>
        <Col>
          <H3>When do you need help?</H3>
        </Col>
      </Row>
      <Spacer1 />
      <DateWrapper width={!editMode ? '490px' : '330px'}>
        <DatePicker
          locale={enUS}
          selected={currentJob?.date}
          onChange={(date) => handleDateChange(date)}
          customInput={<DatePickerDateCustom />}
          minDate={moment().add(1, "day").toDate()}
          popperPlacement="bottom"
        />
        <DatePicker
          selected={selectedTime}
          onChange={(e) => onTimeChange(e as Date)}
          customInput={<DatePickerTime />}
          showTimeSelect
          showTimeSelectOnly
          timeIntervals={15}
          timeCaption="Time"
          dateFormat="h:mm aa"
          popperPlacement="bottom"
        />
        {!editMode && (
          <DropdownFormControl
            as="select"
            onChange={(event: React.ChangeEvent<HTMLSelectElement>) => selectIsRecurring(null, event)}
            disabled={currentJob?.date === undefined}
            value={`${currentJob?.isRecurring}`}
          >
            <option value="false">Does not repeat</option>
            <option value="true">Recurring job</option>
          </DropdownFormControl>
        )}
      </DateWrapper>
      <Spacer2 />
      <Row>
        <Col>
          <RegularText>All times are in the Pacific Time Zone (PT)</RegularText>
        </Col>
      </Row>
      <Spacer2 />

      {currentJob?.isRecurring && (
        <>
          <Spacer2 />
          <H4>Select the recurring days</H4>
          <FromGroupWrapper>
            <CheckboxFormGroup
              field="recurringDays"
              optionsValueArray={["1", "2", "3", "4", "5", "6", "0"]}
              optionsLabelArray={["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"]}
              singleValue={false}
              valuesArray={currentJob?.recurringDays?.map(i => i.toString())}
              onClick={selectRecurringDays}
            />
          </FromGroupWrapper>
          <Spacer1 />
          <H4>Recurring Job End Date *</H4>
          <DateWrapper display="block">
            <DatePicker
              locale={enUS}
              selected={currentJob.recurringEndDate}
              onChange={(date) => selectRecurringEndDate(date)}
              customInput={<DatePickerDateCustom />}
              minDate={currentJob.date ? moment(currentJob.date).add(1, "day").toDate(): moment().add(1, "day").toDate()}
              popperPlacement="bottom"
              disabled={currentJob.recurringDays?.length === 0}
            />
          </DateWrapper>
          <Spacer3 />
          {
            currentJob.recurringDates?.length === 0 &&
            currentJob.recurringDays &&
            currentJob.recurringDays.length > 0 &&
            currentJob.recurringEndDate &&
            <Alert variant="warning">Selected recurring days are invalid.</Alert>
          }
        </>
      )}

      <Row>
        <Col>
          <H3>Shift length:</H3>
        </Col>
      </Row>
      <Spacer1 />
      <FromGroupWrapper>
        <CheckboxFormGroup
          field="shiftLength"
          optionsValueArray={shiftLengths}
          optionsLabelArray={shiftLengths.map((e) => `${e} hour${parseInt(e) > 1 ? "s" : ""}`)}
          singleValue={true}
          value={currentJob?.shiftLength?.toString()}
          onClick={handleCheckboxChange}
        />
      </FromGroupWrapper>
      <Spacer3 />
      {
        showOnBehalfSection && 
        <>
          <Row>
            <Col>
              <H3>Are you creating the job for {profileName}?</H3>
            </Col>
          </Row>
          <Spacer1 />
          <FromGroupWrapper>
            <CheckboxFormGroup
              field="isOwner"
              optionsValueArray={["true", "false"]}
              optionsLabelArray={["Yes", "No"]}
              singleValue={true}
              value={`${currentJob?.isOwner}`}
              onClick={selectIsOwner}
            />
          </FromGroupWrapper>
        </>
      }

      {!currentJob?.isOwner && (
        <>
          <Form.Row>
            <TextFormGroup
              md="6"
              field="firstName"
              value={currentJob?.firstName}
              required={true}
              labelHtml="First Name"
              ariaLabel="First Name"
              feedbackHtml={"Please provide a valid first name."}
              onChange={(event: React.ChangeEvent<FormControlElement>) => handleFieldChange("firstName", event)}
            />
            <TextFormGroup
              md="6"
              field="lastName"
              value={currentJob?.lastName}
              required={true}
              labelHtml="Last Name"
              ariaLabel="Last Name"
              feedbackHtml={"Please provide a valid last name."}
              onChange={(event: React.ChangeEvent<FormControlElement>) => handleFieldChange("lastName", event)}
            />
          </Form.Row>

          <Form.Row>
            <TextFormGroup
              md="4"
              field="relationship"
              value={currentJob?.relationship}
              required={true}
              labelHtml="Relationship"
              ariaLabel="Relationship"
              feedbackHtml={"Please provide a valid relationship."}
              onChange={(event: React.ChangeEvent<FormControlElement>) => handleFieldChange("relationship", event)}
            />
            <TextFormGroup
              md="4"
              field="age"
              value={currentJob?.age}
              required={true}
              labelHtml="Age"
              ariaLabel="Age"
              feedbackHtml={"Please provide a valid age."}
              onChange={(event: React.ChangeEvent<FormControlElement>) => handleFieldChange("age", event)}
            />

            <Form.Group as={Col} md="4">
              <FormLabel htmlFor="gender">Gender{requiredStar}</FormLabel>
              <DropdownFormControl
                className="col-md-12"
                as="select"
                value={currentJob?.gender}
                required
                onChange={(event: React.ChangeEvent<FormControlElement>) => handleFieldChange("gender", event)}
                aria-label="Gender"
              >
                {Object.values(Genders).map((gender) => (
                  <option key={`gender-${gender}`}>{gender}</option>
                ))}
              </DropdownFormControl>
              <Form.Control.Feedback type="invalid">Please select an option.</Form.Control.Feedback>
            </Form.Group>
          </Form.Row>
        </>
      )}
      {currentJob?.shiftLength && <h5>Cost {currentJob.isRecurring && 'Per Job'}: ${shiftToPrices[currentJob?.shiftLength]}</h5>}
    </>
  );
};

const FromGroupWrapper = styled.div`
  width: 350px;
  margin: 0 auto;

  .form-group {
    display: flex;
    justify-content: space-around;
  }

  button {
    padding: 16px 18px;
    border-color: ${(props) => props.theme.colors.mercury};
    display: initial;
  }
`;

const DatePickerTime = React.forwardRef((value: any, onClick: any) => (
  <>
    <FormControl
      onClick={value.onClick}
      onChange={() => {}}
      value={value.value}
      placeholder={"Start Time"}
      plaintext={true}
    />
    <FontAwesomeIcon icon={faClock} color={theme.colors.silver} />
  </>
));

export { PatientAddEditJobStep1 };
