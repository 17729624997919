import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { ReactComponent as LogoImage } from "../assets/logo.svg";
import { Link } from "react-router-dom";
import {
  AUTH_ROUTE,
  PATIENT_PROFILE_ROUTE,
  PATIENT_JOBS_ROUTE,
  PATIENT_NEW_JOB_ROUTE,
  PATIENT_ACCOUNT_HISTORY_ROUTE,
} from "../constants/routes";
import { usePatientAuth } from "../hooks/use-patient-auth";
import Container from "react-bootstrap/Container";
import { Spacer2, Spacer4 } from "./spacers";
import { LinkButton } from "./buttons";

export const HEADER_HEIGHT = 130;

const Header: React.FC = () => {
  const auth = usePatientAuth();
  // const [setShowSignButtons] = useState<boolean | undefined>();
  const [setupComplete, setSetupComplete] = useState<boolean>(false);
  const [showProfileMenu, setShowProfileMenu] = useState(false);

  useEffect(() => {
    if (!auth.loading) {
      if (auth.profile?.firstName && auth.profile?.ccReady) {
        setSetupComplete(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth.loading]);

  return (
    <HeaderContainer fluid>
      <ContentContainer>
        <Logo />
        <ContentWrapper>
          {auth.user && setupComplete && (
            <>
              <LinkWrapper to={PATIENT_JOBS_ROUTE}>
                <p>My Jobs</p>
              </LinkWrapper>
              <Spacer2 />
              <div
                className="h-100"
                onMouseEnter={() => setShowProfileMenu(true)}
                onMouseLeave={() => setTimeout(() => setShowProfileMenu(false))}
              >
                <LinkWrapper to={PATIENT_PROFILE_ROUTE}>
                  <p>Care Profile</p>
                </LinkWrapper>
                <MenuWrapper active={showProfileMenu}>
                  <Menu>
                    <Link to={PATIENT_PROFILE_ROUTE}>Profile</Link>
                    <Link to={PATIENT_ACCOUNT_HISTORY_ROUTE}>Account</Link>
                    <Link to={AUTH_ROUTE} onClick={async () => await auth.signOut()}>
                      Logout
                    </Link>
                  </Menu>
                </MenuWrapper>
              </div>
              <Spacer4 />
              <Spacer2 />
              <LinkButton to={PATIENT_NEW_JOB_ROUTE}>Post a Job</LinkButton>
            </>
          )}
          {!auth.loading && auth.user && !setupComplete && (
            <LinkButton onClick={async () => await auth.signOut()} to={PATIENT_NEW_JOB_ROUTE}>
              Logout
            </LinkButton>
          )}
        </ContentWrapper>
      </ContentContainer>
    </HeaderContainer>
  );
};

const Logo: React.FC = () => (
  <ContentWrapper>
    <Link to={AUTH_ROUTE}>
      <LogoImage />
    </Link>
  </ContentWrapper>
);

interface MenuWrapperProps {
  active: boolean;
}

const MenuWrapper = styled.div<MenuWrapperProps>`
  position: absolute;
  flex-direction: column;
  top: 130px;

  padding-top: 10px;
  display: ${(props) => (props.active ? "flex" : "none")};
`;

const Menu = styled.div`
  background-color: white;
  box-shadow: 1px 1px 3px 0 ${(props) => props.theme.colors.silver};

  &:after {
    content: "";
    display: block;
    position: absolute;
    bottom: 141px;
    width: 0;
    height: 0;
    left: 56px;
    border: 10px solid transparent;
    border-bottom-color: #fff;
  }

  a {
    display: block;
    text-align: center;
    padding: 10px 32px;
    font-size: 18px;
    color: ${(props) => props.theme.colors.jellyBean};

    &:hover {
      text-decoration: none;
      background-color: ${(props) => props.theme.colors.jellyBean};
      color: white;
    }
  }
`;

const LinkWrapper = styled(Link)`
  height: 100%;
  width: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 8px solid white;

  &:hover {
    text-decoration: none !important;
  }

  p {
    margin: 10px 0 0 0;
  }

  &:hover {
    border-bottom: 8px solid ${(props) => props.theme.colors.jellyBean};
  }
`;

const FlexContainer = styled(Container).attrs((props) => ({
  className: "d-flex",
}))``;

const HeaderContainer = styled(FlexContainer).attrs((props) => ({
  className: "fixed-top bg-white",
}))`
  height: ${HEADER_HEIGHT}px;
  box-shadow: 1px 5px 9px 0 rgba(195, 195, 195, 0.45);
`;

const ContentContainer = styled(FlexContainer)`
  justify-content: space-between;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  p,
  .btn {
    font-size: 18px !important;
  }
`;

export default Header;
