import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import "firebase/storage";
import "firebase/functions";
import { firebaseConfig } from "./firebase-config";

firebase.initializeApp(firebaseConfig);

export const firestore = firebase.firestore();

// if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
//   firestore.settings({
//     host: "localhost:8080",
//     ssl: false,
//   });
//
//   firebase.functions().useFunctionsEmulator("http://localhost:5001");
// }

export const firebaseAuth = firebase.auth();
export const storage = firebase.storage();
export const firebaseFunctions = firebase.functions();

export default firebase;
