import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import useRequireAuth from "../hooks/use-require-auth";
import { PageLoading } from "../components/loading";
import { MainContentWrapper } from "../components/layout";
import { firebaseFunctions, firestore } from "../firebase";
import { JOBS_COLLECTION, PRACTITIONERS_PROFILES_COLLECTION, REVIEWS_COLLECTION } from "../constants/collections";
// eslint-disable-next-line no-unused-vars
import { RouteComponentProps } from "react-router-dom";
import styled from "styled-components";
import { Spacer1, Spacer2, Spacer3, Spacer4 } from "../components/spacers";
import { fromFirebase, updateJobStatus, updateRecurringJobStatus } from "../hooks/use-jobs";
import { DAY, TIME } from "../constants/dates";
import Row from "react-bootstrap/Row";
import { CheckboxButton } from "../components/checkbox-form-group";
import { BigLabel, BoldLarge, H2, Notes } from "../components/text";
// eslint-disable-next-line no-unused-vars
import { Job, JobApplicant, JobFields, JobStatus } from "../types/jobs";
import { Avatar } from "../components/avatar";
import ReactStars from "react-rating-stars-component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faExclamationCircle } from "@fortawesome/pro-solid-svg-icons";
import { theme } from "../components/theme";
import { APPLICANT_PATH, PATIENT_EDIT_JOB_ROUTE_PATH, PATIENT_HOME_ROUTE } from "../constants/routes";
import { Button, LinkButton } from "../components/buttons";
import ModalPopup from "../components/modal-popup";
import { PATIENT_JOBS_ROUTE } from "../constants/routes";
import GenericError from "../components/generic-error";
import { NOTIFY_PROVIDER } from "../constants/callable-functions";
import { CANCELLED_JOB_NOTIFICATION_TYPE } from "../constants/notifications";
// eslint-disable-next-line no-unused-vars
import { FormControlElement } from "../types/form";
import { TextAreaFormControl } from "../components/forms";
import { MAX_TEXTAREA_LENGTH } from "../constants/constants";
// eslint-disable-next-line no-unused-vars
import { Review } from "../types/reviews";
import firebase from "../firebase";
// eslint-disable-next-line no-unused-vars
import { getPatientDisplayName } from "../constants/profile";
import { usePatientAuth } from "../hooks/use-patient-auth";
import useScrollTop from "../hooks/use-scroll-to-top";
import { CareNeeds } from "../types/user";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import ReportProblemModal from "../components/report-problem-modal";
import { getFromTimeZone } from "../constants/timezones";

export type data_key = keyof typeof CareNeeds;

export interface MatchParams {
  jobId: string;
}

interface PatientJobProps extends RouteComponentProps<MatchParams> {}

const PatientJob: React.FC<PatientJobProps> = (props) => {
  const requireAuth = useRequireAuth();
  const history = useHistory();
  const jobId = props.match.params.jobId;
  const [loading, setLoading] = useState(true);
  const [jobDocument, setJobDocument] = useState<firebase.firestore.DocumentSnapshot>();
  const [error, setError] = useState<boolean>(false);
  const [rating, setRating] = useState<number>();
  const [review, setReview] = useState<string>();
  const [applyingReview, setApplyingReview] = useState<boolean>(false);
  const [isCancelModalVisible, setIsCancelModalVisible] = useState<boolean>(false);
  const [isCancelRecurringModalVisible, setIsCancelRecurringModalVisible] = useState<boolean>(false);
  const [isReportModalVisible, setIsReportModalVisible] = useState<boolean>(false);
  const { profile, user } = usePatientAuth();
  useScrollTop();

  useEffect(() => {
    firestore
      .collection(JOBS_COLLECTION)
      .doc(props.match.params.jobId)
      .get()
      .then((doc) => {
        setJobDocument(doc);
        setLoading(false);
      })
      .catch((e) => {
        setError(true);
        setLoading(false);
      });
  }, [props.match.params.jobId]);

  if (!requireAuth.user || !profile || loading) {
    return (
      <MainContentWrapper color="white">
        <PageLoading />
      </MainContentWrapper>
    );
  }

  const job = fromFirebase(jobDocument);
  const isPaid = job.status === JobStatus.PAID;

  const jobDate = getFromTimeZone(job.date, profile?.city);
  const day = jobDate.format(DAY);
  const timeFrom = jobDate.format(TIME);
  const timeTo = jobDate.add(job.shiftLength, "hours").format(TIME);
  const confirmedApplicant = job.applicants?.find((applicant: JobApplicant) => applicant.confirmed);

  let showCancelAction = false;
  let showReportProblemAction = false;

  if (Date.now() > job.date.getTime()) {
    showReportProblemAction = true;
  } else {
    showCancelAction = true;
  }

  if (job && job.patientUID !== requireAuth.user.firebaseUser?.uid) {
    history.push(PATIENT_HOME_ROUTE);
  }

  const onCancelJob = () => {
    if (setIsCancelModalVisible) {
      setIsCancelModalVisible(true);
    }
  };

  const onCancelJobConfirmed = () => {
    notifyProvider(job.id, confirmedApplicant);

    updateJobStatus(job, JobStatus.CANCELLED).then(() => {
      history.push(PATIENT_JOBS_ROUTE);
    });
  };

  const notifyProvider = async (jobId: any, applicant: any) => {
    const notifyProvider = firebaseFunctions.httpsCallable(NOTIFY_PROVIDER);
    await notifyProvider({
      applicantId: applicant?.uid,
      type: CANCELLED_JOB_NOTIFICATION_TYPE,
      jobId: jobId,
    }).catch((error) => console.error(error));
  };

  const onCancelRecurringJobs = async () => {
    if (!user || !user?.firebaseUser) return;

    const recurringJobId = job.parentJobId;
    const docs = await firestore
      .collection(JOBS_COLLECTION)
      .where(JobFields[JobFields.patientUID], "==", user?.firebaseUser?.uid)
      .where(JobFields[JobFields.parentJobId], "==", job.parentJobId)
      .get();

    const jobs = docs.docs.map((jobDocument) => fromFirebase(jobDocument));

    jobs.forEach(async (job) => {
      const jobApplicant = job.applicants?.find((applicant) => applicant.confirmed);

      await notifyProvider(job.id, jobApplicant);
      await updateJobStatus({ id: job.id } as any, JobStatus.CANCELLED);
    });

    await updateRecurringJobStatus({ id: recurringJobId } as any, JobStatus.CANCELLED);
    history.push(PATIENT_JOBS_ROUTE);
  };

  const handleReview = async () => {
    setApplyingReview(true);

    if (typeof rating === "number" && review && review?.length <= MAX_TEXTAREA_LENGTH) {
      try {
        const nextReview: Review = {
          createdAt: firebase.firestore.Timestamp.fromDate(new Date()),
          rating,
          review,
          reviewer: getPatientDisplayName(profile.firstName, profile.lastName),
          jobId: job.id,
        };

        await firestore
          .collection(PRACTITIONERS_PROFILES_COLLECTION)
          .doc(job.confirmedApplicant)
          .collection(REVIEWS_COLLECTION)
          .add(nextReview);

        setApplyingReview(false);
        history.replace(PATIENT_HOME_ROUTE);
      } catch (error) {
        setApplyingReview(false);
        console.error(error);
      }
    }
  };

  const onEditJob = () => {
    history.push(`${PATIENT_EDIT_JOB_ROUTE_PATH}/${jobId}`);
  };

  return (
    <>
      <ModalPopup
        isVisible={isCancelModalVisible}
        setIsVisible={setIsCancelModalVisible}
        title="Cancel"
        mainCopy="Are you sure you want to cancel this job?"
        hint="This will remove the job from your list, as well as all applicants' lists."
        onConfirm={() => onCancelJobConfirmed()}
        confirmCopy="Cancel Job"
        cancelCopy="Keep job as is"
      />
      <ModalPopup
        isVisible={isCancelRecurringModalVisible}
        setIsVisible={setIsCancelRecurringModalVisible}
        title="Cancel"
        mainCopy="Are you sure you want to cancel this series of jobs?"
        hint="This will remove all jobs from your list, as well as all applicant's lists."
        onConfirm={() => onCancelRecurringJobs()}
        confirmCopy="Cancel all Jobs"
        cancelCopy="Keep jobs as is"
      />
      <ReportProblemModal
        isVisible={isReportModalVisible}
        onConfirm={() => {}}
        onCancel={() => setIsReportModalVisible(false)}
        jobId={job.id}
      />
      <MainContentWrapper color="white">
        {error ? (
          <GenericError />
        ) : (
          <>
            <Spacer4 />
            <JobWrapper>
              <DatesWrapper>
                <BigLabel>DATE</BigLabel>
                <LabelContent>{day}</LabelContent>
                <Spacer2 />
                <BigLabel>TIME (PT)</BigLabel>
                <LabelContent>{`${timeFrom} - ${timeTo}`}</LabelContent>
                {!job.isOwner && (
                  <>
                    <Spacer2 />
                    <BigLabel>ON BEHALF TO</BigLabel>
                    <LabelContent>{`${job.firstName} ${job.lastName}`}</LabelContent>
                    <BigLabel>RELATIONSHIP</BigLabel>
                    <LabelContent>{job.relationship}</LabelContent>
                    <BigLabel>AGE</BigLabel>
                    <LabelContent>{job.age}</LabelContent>
                    <BigLabel>GENDER</BigLabel>
                    <LabelContent>{job.gender}</LabelContent>
                  </>
                )}
              </DatesWrapper>
              <div className="d-flex flex-column flex-grow-1">
                {!isPaid && (
                  <Row className="justify-content-between">
                    <BigLabel>TASKS</BigLabel>
                    <BigLabel>
                      {showCancelAction && (
                        <ActionSpan onClick={onCancelJob}>
                          <ActionIcon icon={faTrash} /> Cancel this Job
                        </ActionSpan>
                      )}
                      {job.parentJobId && (
                        <ActionSpan onClick={() => setIsCancelRecurringModalVisible(true)}>
                          <ActionIcon icon={faTrash} /> Cancel Recurring Series
                        </ActionSpan>
                      )}
                      {job.status === JobStatus.PENDING && (
                        <ActionSpan onClick={onEditJob}>
                          <ActionIcon icon={faEdit} /> Edit this Job
                        </ActionSpan>
                      )}
                      {showReportProblemAction && (
                        <ActionSpan onClick={() => setIsReportModalVisible(true)}>
                          <ActionIcon icon={faExclamationCircle} /> Report a problem
                        </ActionSpan>
                      )}
                    </BigLabel>
                  </Row>
                )}
                <Spacer1 />
                <Row>
                  {job.careNeeds.map((value, index) => {
                    return (
                      <ButtonWrapper key={index}>
                        <CheckboxButton type="button" className="confirm selected">
                          {CareNeeds[value as data_key]}
                        </CheckboxButton>
                      </ButtonWrapper>
                    );
                  })}
                </Row>
                <Spacer3 />
                <Row>
                  <BigLabel>DESCRIPTION</BigLabel>
                </Row>
                <Row>
                  <Notes>{job.notes}</Notes>
                </Row>
                <Spacer2 />
                {confirmedApplicant ? (
                  <>
                    <Row>
                      <H2>Confirmed Applicant:</H2>
                    </Row>
                    <Spacer1 />
                    <Row className="mb-4">
                      <ApplicantItem applicant={confirmedApplicant} job={job} hideActions={isPaid} confirmed />
                    </Row>
                  </>
                ) : job.applicants?.length ? (
                  <>
                    <Row>
                      <H2>Applicants</H2>
                    </Row>
                    <Spacer1 />
                    <Row className="mb-4">
                      <ApplicantsList applicants={job.applicants} job={job} />
                    </Row>
                  </>
                ) : (
                  <></>
                )}
                {isPaid && !job.reviewed && (
                  <>
                    <Spacer3 />
                    <Row>
                      <H2>Review the job:</H2>
                    </Row>
                    <Row>
                      <p className="mt-1">Choose a rating</p>
                      <Spacer2 />
                      <StarsWrapper>
                        <ReactStars
                          count={5}
                          value={rating}
                          edit={true}
                          onChange={(value: number) => setRating(value)}
                          size={22}
                          isHalf
                          activeColor={theme.colors.yellowOrange}
                          color={theme.colors.alto}
                        />
                      </StarsWrapper>
                    </Row>
                    <Row>
                      <TextAreaFormControl
                        as="textarea"
                        rows={6}
                        value={review}
                        onChange={(event: React.ChangeEvent<FormControlElement>) => {
                          setReview(event.currentTarget.value.substring(0, MAX_TEXTAREA_LENGTH));
                        }}
                        placeholder="Please write your review here"
                      />
                    </Row>
                    <Spacer2 />
                    <div className="d-flex justify-content-center">
                      {!rating || !review || applyingReview ? (
                        <DisabledButton>
                          <Button onClick={() => handleReview()}>Review</Button>
                        </DisabledButton>
                      ) : (
                        <Button onClick={() => handleReview()}>Review</Button>
                      )}
                    </div>
                    <Spacer4 />
                  </>
                )}
              </div>
            </JobWrapper>
          </>
        )}
      </MainContentWrapper>
    </>
  );
};

const DisabledButton = styled.div`
  pointer-events: none;
  filter: opacity(0.6);
`;

const StarsWrapper = styled.div`
  * {
    outline: none;
  }
`;

interface ApplicantsProps {
  applicants: JobApplicant[] | undefined;
  job: Job;
}

const ApplicantsList: React.FC<ApplicantsProps> = (props) => {
  const { applicants, job } = props;
  const confirmed = applicants?.find((applicant: JobApplicant) => applicant.confirmed);

  if (confirmed) {
    return <ApplicantItem applicant={confirmed} job={job} confirmed />;
  }

  return (
    <>
      {applicants?.map((applicant: JobApplicant, index) => (
        <ApplicantItem key={index} applicant={applicant} job={job} />
      ))}
    </>
  );
};

interface ApplicantItemProps {
  applicant: JobApplicant | undefined;
  job: Job;
  confirmed?: boolean;
  hideActions?: boolean;
}

const ApplicantItem: React.FC<ApplicantItemProps> = (props) => {
  const { applicant, job, confirmed, hideActions } = props;

  return (
    <ApplicantWrapper confirmed={confirmed}>
      <div className="d-flex">
        <Avatar single applicant={applicant} />
        <Spacer1 />
        <Spacer2 />
        <div className="d-flex flex-column justify-content-center">
          <BoldLarge>{applicant?.displayName}</BoldLarge>
          {!hideActions && applicant?.rating && (
            <ReactStars
              count={5}
              value={applicant?.rating}
              edit={false}
              onChange={() => {}}
              size={22}
              isHalf
              activeColor={theme.colors.yellowOrange}
              color={theme.colors.alto}
            />
          )}
          <Spacer1 />
        </div>
      </div>
      <div className="d-flex align-items-center">
        {!hideActions && (
          <>
            {confirmed ? (
              <LinkButton
                to={{
                  pathname: `${APPLICANT_PATH}/${applicant?.uid}/${job.id}`,
                  state: job,
                }}
              >
                Confirmed
              </LinkButton>
            ) : (
              <LinkButton
                to={{
                  pathname: `${APPLICANT_PATH}/${applicant?.uid}/${job.id}`,
                  state: job,
                }}
              >
                View
              </LinkButton>
            )}
          </>
        )}
      </div>
    </ApplicantWrapper>
  );
};

interface ApplicantWrapperProps {
  confirmed?: boolean;
}

const ButtonWrapper = styled.div`
  display: flex;
  margin: 0 8px 8px 0;

  button {
    cursor: default !important;
  }
`;

const ApplicantWrapper = styled.div<ApplicantWrapperProps>`
  display: flex;
  flex-grow: 1;
  padding: 16px 24px;
  justify-content: space-between;
  background-color: ${(props) => (props.confirmed ? "white" : props.theme.colors.wildSand)};
  border: 4px solid ${(props) => (props.confirmed ? props.theme.colors.jellyBean : props.theme.colors.wildSand)};

  img {
    filter: ${(props) => (props.confirmed ? "none" : "inherit")};
  }

  &:hover {
    background-color: white;
    border: 4px solid ${(props) => props.theme.colors.jellyBean};

    img {
      filter: none;
    }
  }
`;

const LabelContent = styled.p`
  font-size: 22px;
  font-weight: bold;
`;

const JobWrapper = styled.div`
  display: flex;
`;

const DatesWrapper = styled.div`
  min-width: 300px;
`;

// const ActionLink = styled(Link)`
//   &:hover {
//     text-decoration: none;
//   }
// `;

const ActionSpan = styled.span`
  font-size: 20px;
  color: ${(props) => props.theme.colors.jellyBean};
  margin-right: 30px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
`;

const ActionIcon = styled(FontAwesomeIcon)`
  color: ${(props) => props.theme.colors.alto};
  margin-right: 8px;
`;

export default PatientJob;
