// eslint-disable-next-line no-unused-vars
import React, { ChangeEvent, useState } from "react";
// eslint-disable-next-line no-unused-vars
import { FormUser } from "../types/user";
import Form from "react-bootstrap/Form";
import { usePatientAuth } from "../hooks/use-patient-auth";
import { Link, useHistory } from "react-router-dom";
import { ButtonText, H1, TextSize } from "../components/text";
import { MainContentWrapper } from "../components/layout";
import { AUTH_ROUTE, PATIENT_HOME_ROUTE, PRIVACY_ROUTE, TERMS_OF_SERVICE_ROUTE } from "../constants/routes";
import { PageLoading } from "../components/loading";
import { Spacer2, Spacer3, Spacer1 } from "../components/spacers";
import { Button, ButtonAvailability } from "../components/buttons";
import { FormControl } from "../components/forms";
import { PASSWORD_REGEX, PATIENT } from "../constants/users";
import { AuthFormWrapper, AuthForm } from "../components/auth";
import PasswordRequirements from "../components/password-requirements";
import { CloseIcon, ModalCard, ModalCardBackdrop } from "../components/modal-parts";
import { ModalCardTitle } from "../components/modal-popup";
import styled from "styled-components";
import { firestore } from "../firebase";
import { NOTIFY_ME_COLLECTION } from "../constants/collections";
import { faTimes } from "@fortawesome/pro-regular-svg-icons";
import { ZIP_CODES } from "../constants/zipcodes";

const SignUp: React.FC = () => {
  const auth = usePatientAuth();
  const [user, setUser] = useState<FormUser>({ email: "", password: "", repeatPassword: "", type: PATIENT });
  const [error, setError] = useState<string>();
  const [success, setSuccess] = useState(false);
  const [zipModalOpen, setZipModalOpen] = useState<boolean>(true);
  const [zipCheck, setZipCheck] = useState<boolean | undefined>(undefined);
  const [zip, setZip] = useState<string>("");
  const [notifyMeEmail, setNotifyMeEmail] = useState("");
  const [savingNotifyMe, setSavingNotifyMe] = useState<boolean>(false);
  const [notifyMeComplete, setNotifyMeComplete] = useState<boolean>(false);
  const history = useHistory();

  const handleUserChange = (field: string, event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;
    const nextUser: FormUser = { ...user, [field]: value };
    setUser(nextUser);
  };

  const handleSignUp = async (event: any) => {
    event.preventDefault();

    if (user.email === "" || user.password === "" || user.repeatPassword === "") {
      setError("Please fill in all the fields.");
      return;
    }

    if (user.password !== user.repeatPassword) {
      setError("Passwords do not match.");
      return;
    }

    if (!user.password.match(PASSWORD_REGEX)) {
      setError("The password does not match the requirements.");
      return;
    }

    try {
      await auth.signUp(user.email, user.password);
      localStorage.setItem(user.email, JSON.stringify({ zip }));
      setSuccess(true);
    } catch (error) {
      setError(error.message);
    }
  };

  const checkZip = () => {
    const zipCodes = { ...ZIP_CODES };
    let allowedZipCode = false;

    for (let city in zipCodes) {
      if (zipCodes[city].includes(parseInt(zip))) {
        allowedZipCode = true;
      }
    }

    setZipCheck(allowedZipCode);
  };

  const saveNotifyMe = async () => {
    setSavingNotifyMe(true);
    try {
      await firestore.collection(NOTIFY_ME_COLLECTION).add({ email: notifyMeEmail, zip });
    } catch (e) {
      console.error(e);
    }

    setNotifyMeComplete(true);
    setSavingNotifyMe(false);
  };

  if (auth.loading)
    return (
      <MainContentWrapper>
        <PageLoading />
      </MainContentWrapper>
    );

  return (
    <>
      {zipModalOpen && (
        <ModalCardBackdrop>
          <AppAvailabilityModalCard>
            <ModalCardTitle className="d-flex justify-content-between align-items-center">
              <span />
              <span>{"Notice"}</span>
              <CloseIcon
                icon={faTimes}
                color="white"
                size="1x"
                onClick={() => {
                  if (zipCheck) {
                    setZipModalOpen(false);
                  } else {
                    history.push(AUTH_ROUTE);
                  }
                }}
              />
            </ModalCardTitle>
            <ModalContentWrapper>
              {zipCheck === undefined && (
                <>
                  <Notice>
                    The CaregiverGO app is currently limited to pre-selected caregivers in specific regions.
                  </Notice>
                  <p>Please type in your ZIP code to check if we are operating in your area.</p>
                  <Center>
                    <ZipWrapper>
                      <FormControl
                        placeholder="Zipcode"
                        value={zip}
                        onChange={(event: any) => setZip(event.target.value)}
                      />
                    </ZipWrapper>
                    <Spacer2 />
                    {zip.length <= 4 ? (
                      <ButtonAvailability>
                        <Button>
                          <ButtonText>Check</ButtonText>
                        </Button>
                      </ButtonAvailability>
                    ) : (
                      <Button onClick={() => checkZip()}>
                        <ButtonText>Check</ButtonText>
                      </Button>
                    )}
                  </Center>
                </>
              )}
              {zipCheck && (
                <>
                  <Notice>Congratulations, we are operating in your area!</Notice>
                  <p>Please proceed by creating a new account on the following screen.</p>
                  <Spacer1 />
                  <Center>
                    <Button onClick={() => setZipModalOpen(false)}>
                      <ButtonText>Create a new account</ButtonText>
                    </Button>
                  </Center>
                </>
              )}
              {zipCheck === false && !notifyMeComplete && (
                <>
                  <Notice>Sorry we are not yet operating in your area.</Notice>
                  <p>
                    If you would like to be notified when CaregiverGO begins accepting applications on your area please
                    provide your email here.
                  </p>
                  <Spacer1 />
                  <Center>
                    <NotifyEmailWrapper>
                      <FormControl
                        type="email"
                        placeholder="Email"
                        value={notifyMeEmail}
                        onChange={(event: any) => setNotifyMeEmail(event.target.value)}
                      />
                    </NotifyEmailWrapper>
                    <Spacer2 />
                    {savingNotifyMe || !notifyMeEmail ? (
                      <ButtonAvailability>
                        <Button>
                          <ButtonText>Notify me</ButtonText>
                        </Button>
                      </ButtonAvailability>
                    ) : (
                      <Button onClick={() => saveNotifyMe()}>
                        <ButtonText>Notify me</ButtonText>
                      </Button>
                    )}
                  </Center>
                </>
              )}
              {notifyMeComplete && (
                <>
                  <Center>
                    <Notice>Thank you!</Notice>
                  </Center>
                  <p>We will notify you soon as CaregiverGO starts operating in your area.</p>
                  <Spacer1 />
                  <Center>
                    <Button onClick={() => history.push(AUTH_ROUTE)}>
                      <ButtonText>Close</ButtonText>
                    </Button>
                  </Center>
                </>
              )}
            </ModalContentWrapper>
          </AppAvailabilityModalCard>
        </ModalCardBackdrop>
      )}
      {success ? (
        <MainContentWrapper align="center">
          <Spacer2 />
          <H1>Thank you!</H1>
          <Spacer3 />
          <AuthFormWrapper>
            <p>A verification email has been sent to your email account.</p>
            <Spacer2 />
            <Link to={PATIENT_HOME_ROUTE}>
              <Button>
                <ButtonText>Log in</ButtonText>
              </Button>
            </Link>
            <Spacer3 />
          </AuthFormWrapper>
        </MainContentWrapper>
      ) : (
        <MainContentWrapper align="center">
          <Spacer2 />
          <H1>Please Sign Up to access</H1>
          <H1>CaregiverGO!</H1>
          <Spacer3 />
          <AuthFormWrapper>
            <AuthForm onSubmit={(event: any) => handleSignUp(event)}>
              <Form.Group controlId="formBasicEmail">
                <FormControl
                  type="email"
                  placeholder="Email"
                  value={user?.email}
                  onChange={(event: any) => handleUserChange("email", event)}
                />
              </Form.Group>
              <Form.Group controlId="formBasicPassword">
                <FormControl
                  type="password"
                  placeholder="Password"
                  onChange={(event: any) => handleUserChange("password", event)}
                />
              </Form.Group>
              <Form.Group controlId="formBasicRepeatPassword">
                <FormControl
                  type="password"
                  placeholder="Repeat Password"
                  onChange={(event: any) => handleUserChange("repeatPassword", event)}
                />
              </Form.Group>
              {error && <p className="text-danger">{error}</p>}
              <PasswordRequirements />
              <LegalWrapper>
                <p className="text-left">
                  By creating an account you agree to CaregiverGO's <br />
                  <Link to={TERMS_OF_SERVICE_ROUTE} target="_blank" rel="noopener">
                    Terms of Service
                  </Link>{" "}
                  and{" "}
                  <Link to={PRIVACY_ROUTE} target="_blank" rel="noopener">
                    Privacy Policy
                  </Link>
                </p>
              </LegalWrapper>
              <Spacer2 />
              <Button>
                <ButtonText>Sign up</ButtonText>
              </Button>
            </AuthForm>
            <Spacer3 />
          </AuthFormWrapper>
        </MainContentWrapper>
      )}
    </>
  );
};

const LegalWrapper = styled.div`
  font-size: 14px;

  a {
    text-decoration: underline;
  }
`;

const ModalContentWrapper = styled.div`
  padding: 32px;
  display: flex;
  flex-direction: column;
  align-items: start;

  p {
    text-align: left;
    font-size: ${(props) => TextSize.regular};
  }
`;

const Notice = styled.p`
  font-size: ${(props) => TextSize.large} !important;
`;

const Center = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const AppAvailabilityModalCard = styled(ModalCard)`
  margin-top: -300px;
`;

const ZipWrapper = styled.div`
  width: 200px;
`;

const NotifyEmailWrapper = styled.div`
  width: 300px;
`;

export default SignUp;
