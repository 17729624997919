import React from "react";
import { ThemeProvider } from "styled-components";

export const theme = {
  colors: {
    // Greys
    alto: "#e0e0e0",
    alabaster: "#F7F7F7",
    wildSand: "#f6f6f6",
    silver: "#c3c3c3",
    silverChalice: "#A3A3A3",
    emperor: "#4F4F4F",
    mercury: "#E6E6E6",
    tundora: "#464646",
    gray: "#8E8E8E",
    boulder: "#777777",

    // Greens
    jellyBean: "#26859A",
    celadon: "#C6E5AE",

    // Blues
    resolutionBlue: "#023281",

    // Yellows
    yellowOrange: "#FEBC42",
  },
};

interface IProps {}
const Theme: React.FC<IProps> = (props) => {
  return <ThemeProvider theme={theme}>{props.children}</ThemeProvider>;
};

export default Theme;
