import React from "react";
import { PageLoadingWrapper } from "./layout";
import styled from "styled-components";
import Spinner from "react-bootstrap/Spinner";

export const PageLoading = () => (
  <PageLoadingWrapper>
    <Loading />
  </PageLoadingWrapper>
);

export const Loading = styled(Spinner).attrs((props) => ({
  animation: "border",
}))`
// @ts-ignore
  color: ${(props) => props.color || props.theme.colors.jellyBean};
`;
