// eslint-disable-next-line no-unused-vars
import { Job, JobApplicant } from "../types/jobs";
import React from "react";
import { Spacer1, Spacer2, Spacer4 } from "./spacers";
import styled from "styled-components";
import { H2 } from "./text";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { DAY, TIME } from "../constants/dates";
import { Link, useHistory } from "react-router-dom";
import { PATIENT_JOB_ROUTE_PATH } from "../constants/routes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Avatar } from "./avatar";
import { getFromTimeZone } from "../constants/timezones";
import { usePatientAuth } from "../hooks/use-patient-auth";
import { faRepeat } from "@fortawesome/pro-solid-svg-icons";

interface JobItemProps {
  job: Job;
}

const JobItem: React.FC<JobItemProps> = (props) => {
  const { job } = props;
  const history = useHistory();
  const { profile } = usePatientAuth();

  const jobDate = getFromTimeZone(job.date, profile?.city);
  const day = jobDate.format(DAY);
  const time = jobDate.format(TIME);

  return (
    <>
      <JobWrapper onClick={() => history.push(`${PATIENT_JOB_ROUTE_PATH}/${job.id}`)}>
        <div>
          <CardTitle>
            <Day>{day}</Day>
            {job.parentJobId && (
              <RepeatLabel>
                <FontAwesomeIcon icon={faRepeat} />
                <span>Recurring Job</span>
              </RepeatLabel>
            )}
          </CardTitle>
          <CardText>{`${time} (PT)/ ${job.shiftLength} hours`}</CardText>
        </div>
        <div>
          <Applicants applicants={job.applicants} />
        </div>
      </JobWrapper>
      {/*{job.status === JobStatus.CONFIRMED && (*/}
      {/*  <PayJobWrapper*/}
      {/*    onClick={async () => {*/}
      {/*      const payJobCall = firebaseFunctions.httpsCallable(PAY_JOB_FUNCTION);*/}
      {/*      try {*/}
      {/*        await payJobCall({ jobId: job.id });*/}
      {/*      } catch (error) {*/}
      {/*        console.error(error);*/}
      {/*      }*/}
      {/*    }}*/}
      {/*  >*/}
      {/*    Pay Job*/}
      {/*  </PayJobWrapper>*/}
      {/*)}*/}
      <Spacer1 />
    </>
  );
};

// const PayJobWrapper = styled.div`
//   background-color: ${(props) => props.theme.colors.jellyBean};
//   padding: 10px;
//   color: white;
//   cursor: pointer;
//   text-align: center;
// `;

export const JobLink = styled(Link)`
  &:hover {
    text-decoration: none;
  }
`;

const JobWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: white;
  padding: 36px 30px;
  height: 160px;
  border-bottom: 6px solid white;
  cursor: pointer;

  &:hover {
    box-shadow: 0 1px 5px 3px ${(props) => props.theme.colors.alto};
    border-bottom: 6px solid ${(props) => props.theme.colors.celadon};
  }
`;

const CardText = styled.p`
  margin: 0;
  font-size: 20px;
`;

const CardTitle = styled.div`
  display: flex;
  gap: 20px;
  align-items: flex-start;
`;

const Day = styled(H2)`
  color: ${(props) => props.theme.colors.jellyBean};
  margin: 0;
`;

const RepeatLabel = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;  
  padding: 5px 10px;
  font-size: 14px;
  background-color: ${(props) => props.theme.colors.celadon};
  border-radius: 30px;
`;

interface ApplicantsProps {
  applicants: JobApplicant[] | undefined;
}

const Applicants: React.FC<ApplicantsProps> = (props) => {
  const { applicants } = props;
  const confirmedApplicant = applicants?.find((applicant: JobApplicant) => applicant.confirmed);

  if (confirmedApplicant) {
    return (
      <ConfirmedApplicantsWrapper>
        <ConfirmedApplicant name={confirmedApplicant.displayName} />
        <Spacer4 />
        <Spacer2 />
        <ApplicantAvatars applicants={[confirmedApplicant]} />
      </ConfirmedApplicantsWrapper>
    );
  }

  if (applicants && applicants.length) {
    return (
      <div className="d-flex">
        <TotalApplicants total={applicants.length} />
        <Spacer4 />
        <Spacer2 />
        <ApplicantAvatars applicants={applicants} />
      </div>
    );
  }

  return <TotalApplicants total={0} />;
};

const ConfirmedApplicantsWrapper = styled.div`
  display: flex;

  img {
    filter: none !important;
  }
`;

interface ConfirmedApplicantProps {
  name: string;
}

const ConfirmedApplicant: React.FC<ConfirmedApplicantProps> = (props) => {
  const { name } = props;

  return (
    <div className="d-flex flex-column">
      <CardTotalApplicants>{name}</CardTotalApplicants>
      <CardText>Confirmed</CardText>
    </div>
  );
};

interface TotalApplicantsProps {
  total: number;
}

const TotalApplicants: React.FC<TotalApplicantsProps> = (props) => {
  const { total } = props;

  return (
    <div className="d-flex flex-column text-center">
      <CardTotalApplicants>{total}</CardTotalApplicants>
      <CardText>{`Applicant${total === 1 ? "" : "s"}`}</CardText>
    </div>
  );
};

const CardTotalApplicants = styled.p`
  margin: 0;
  font-size: 32px;
  color: ${(props) => props.theme.colors.jellyBean};
`;

interface ApplicantAvatarsProps {
  applicants: JobApplicant[];
}

const ApplicantAvatars: React.FC<ApplicantAvatarsProps> = (props) => {
  const { applicants } = props;

  return (
    <>
      {applicants.map((applicant: JobApplicant, index) => {
        return <Avatar key={index} applicant={applicant} />;
      })}
      {applicants.length > 3 && (
        <Plus>
          <FontAwesomeIcon icon={faPlus} color="white" />
        </Plus>
      )}
    </>
  );
};

const Plus = styled.div`
  width: 46px;
  height: 46px;
  background-color: ${(props) => props.theme.colors.jellyBean};
  border-radius: 50%;
  font-size: 38px;
  color: white;
  display: inline-flex;
  align-items: center;
  align-self: center;
  justify-content: center;
  margin-left: -20px;
  z-index: 1;

  svg {
    width: 22px !important;
  }
`;

export { JobItem };
