import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt, faUserCircle, faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import React from "react";
import { theme } from "./theme";

export const CalendarIcon = <FontAwesomeIcon icon={faCalendarAlt} color={theme.colors.jellyBean} size="3x" />;
export const AvatarIcon = <FontAwesomeIcon icon={faUserCircle} color={theme.colors.jellyBean} size="3x" />;
export const PencilIcon = <FontAwesomeIcon icon={faPencilAlt} color={theme.colors.jellyBean} size="3x" />;

// // TODO use proper spacing
// export const FontAwesomeIconInside = styled(FontAwesomeIcon)`
//
//   * svg {
//     height: 30px !important;
//   }
// `;
