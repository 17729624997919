// eslint-disable-next-line no-unused-vars
import React, { ChangeEvent, useState } from "react";
// eslint-disable-next-line no-unused-vars
import { FormUser } from "../types/user";
import Form from "react-bootstrap/Form";
import { usePatientAuth } from "../hooks/use-patient-auth";
import { Redirect } from "react-router-dom";
import { ButtonText, H1, TextLink, TextSize } from "../components/text";
import { MainContentWrapper } from "../components/layout";
import { FORGOT_PASSWORD_ROUTE, PATIENT_HOME_ROUTE, SIGN_UP_ROUTE } from "../constants/routes";
import { PageLoading } from "../components/loading";
import { Spacer2, Spacer3 } from "../components/spacers";
import { Button } from "../components/buttons";
import { FormControl } from "../components/forms";
import { PATIENT } from "../constants/users";
import { AuthFormWrapper, AuthForm } from "../components/auth";

const Auth: React.FC = () => {
  const auth = usePatientAuth();
  const [user, setUser] = useState<FormUser>({ email: "", password: "", type: PATIENT });
  const [error, setError] = useState<string>();

  const handleUserChange = (field: string, event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;
    const nextUser: FormUser = { ...user, [field]: value };
    setUser(nextUser);
  };

  const handleSignIn = async (event: any) => {
    event.preventDefault();

    if (!auth) return;
    if (!user.email || !user.password) return;

    try {
      await auth.signIn(user.email, user.password);
    } catch (error) {
      setError(error.message);
    }
  };

  if (auth.loading)
    return (
      <MainContentWrapper>
        <PageLoading />
      </MainContentWrapper>
    );

  return (
    <>
      {auth.user ? (
        <Redirect to={PATIENT_HOME_ROUTE} />
      ) : (
        <MainContentWrapper align="center">
          <Spacer2 />
          <H1>Welcome to CaregiverGO!</H1>
          <H1>Please sign in:</H1>
          <Spacer3 />
          <AuthFormWrapper>
            <AuthForm onSubmit={(event: any) => handleSignIn(event)}>
              <Form.Group controlId="formBasicEmail">
                <FormControl
                  type="email"
                  placeholder="Email"
                  value={user?.email}
                  onChange={(event: any) => handleUserChange("email", event)}
                />
              </Form.Group>
              <Form.Group controlId="formBasicPassword">
                <FormControl
                  type="password"
                  placeholder="Password"
                  onChange={(event: any) => handleUserChange("password", event)}
                />
              </Form.Group>
              {error && <p className="text-danger">{error}</p>}
              <Spacer2 />
              <Button>
                <ButtonText>Sign in</ButtonText>
              </Button>
            </AuthForm>
            <Spacer2 />
            <TextLink to={FORGOT_PASSWORD_ROUTE} size={TextSize.large}>
              Forgot your password?
            </TextLink>
            <Spacer3 />
            <TextLink to={SIGN_UP_ROUTE} size={TextSize.large}>
              I don't have an account.
            </TextLink>
            <Spacer3 />
          </AuthFormWrapper>
        </MainContentWrapper>
      )}
    </>
  );
};

export default Auth;
